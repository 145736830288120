/* app.js */

.autocomplete {
  position: relative;
  display: inline-block;
  width: 100%;
}

.autocomplete-input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #41546b80;
}

.autocomplete-items {
  border: 1px solid #d4d4d4;
  border-top: none;
  position: absolute;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
}

.option {
  padding: 10px;
  cursor: pointer;
}

.option:hover {
  background-color: #e9e9e9;
}
