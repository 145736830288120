@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Jost:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Jost", sans-serif;
  scroll-behavior: smooth;
}
.ff_poppins {
  font-family: "Poppins", sans-serif !important;
}

/* Marquee styles */
.marquee {
  --gap: 100px;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
}

.marquee_content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  gap: var(--gap);
}

.marquee_content img {
  width: 200px;
  height: 100px;
  object-fit: contain;
  pointer-events: none;
}

.marquee_item {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

@keyframes scroll-reverse {
  from {
    transform: translateX(calc(-100% - var(--gap)));
  }

  to {
    transform: translateX(0);
  }
}

.enable-animation .marquee_content {
  animation: scroll 65s linear infinite;
}

.reverse-animation .marquee_content {
  animation: scroll-reverse 65s linear infinite;
}

.marquee:hover .marquee_content {
  animation-play-state: paused;
}

.paused .marquee_content {
  animation-play-state: paused;
}

@layer components {
  .solution_card {
    @apply max-w-[345px] flex flex-col sm:gap-5 gap-4 sm:text-start text-center max-sm:flex max-sm:flex-col max-sm:items-center;
  }

  .solution_title {
    @apply font-josefin w-fit font-semibold text-3xl leading-md text-gray relative after:absolute after:left-0 after:bottom-0 after:w-full after:h-[2px] after:bg-gray;
  }

  .common_heading {
    @apply text-orange underline sm:decoration-[3px] decoration-2 sm:underline-offset-[6px] underline-offset-4;
  }

  .contact_frame {
    @apply w-full max-w-[522px] pointer-events-none hidden lg:block absolute top-[230px] -z-10;
  }
}

.customScroll::-webkit-scrollbar {
  width: 4px;
  margin-left: 3px !important;
}

.customScroll::-webkit-scrollbar-thumb {
  background-color: #ed8c0c;
}

.date-input::-webkit-calendar-picker-indicator {
  display: none;
}

.date-input::-webkit-clear-button {
  display: none;
}

/* get in touch */

.swiper-button-next,
.swiper-button-prev {
  background-color: white;
  width: 72px !important;
  height: 72px !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 57% !important;
  transform: translateY(-50%);
  border: 1px solid #41546b;
  transition: all 300ms linear;
}

.swiper-button-next:hover {
  background: orange;
  border: 1px solid;
}

.swiper-button-prev:hover {
  background: orange;
  border: 1px solid;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: "";
  width: 30px;
  height: 27px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.swiper-button-next::after {
  background-image: url("./assets/images/contact/svg/slider-right-arrow.svg");
}

.swiper-button-prev::after {
  background-image: url("./assets/images/contact/svg/slider-left-arrow.svg");
}

.swiper-button-next {
  right: 0 !important;
}

.swiper-button-prev {
  left: 0 !important;
}

.swiper {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.swiper-button-prev,
.swiper-button-next {
  color: transparent !important;
}

.swiper-pagination {
  display: none !important;
}

/* svg icons */

.social-icon img {
  transition: filter 0.3s ease-in-out;
}

.social-icon:hover .social-icon-twitter {
  filter: invert(48%) sepia(85%) saturate(2410%) hue-rotate(176deg)
    brightness(102%) contrast(96%);
}

.social-icon:hover .social-icon-linkedin {
  filter: invert(29%) sepia(100%) saturate(2254%) hue-rotate(197deg)
    brightness(95%) contrast(84%);
}

.social-icon:hover .social-icon-instagram {
  filter: invert(37%) sepia(34%) saturate(2695%) hue-rotate(319deg)
    brightness(98%) contrast(92%);
}

.social-icon:hover .social-icon-facebook {
  filter: invert(40%) sepia(57%) saturate(2382%) hue-rotate(200deg)
    brightness(102%) contrast(94%);
}

@media (max-width: 1600px) {
  .swiper-button-next,
  .swiper-button-prev {
    width: 45px !important;
    height: 45px !important;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    width: 23px !important;
    height: 21px !important;
  }
}
input {
  font-size: 15px !important;
}
@media (max-width: 1200px) {
  .marquee_content {
    gap: 50px !important;
  }
}

@media (max-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    width: 30px !important;
    height: 30px !important;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    width: 15px !important;
    height: 14px !important;
  }

  .marquee_content {
    gap: 0 !important;
  }
}
